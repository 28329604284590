html {
  @apply overflow-x-hidden;
  width: 100vw;

  &.active-mm {
    overflow: hidden;
  }
}

body {
  @apply overflow-x-hidden;
}

.app {
  @apply w-full overflow-x-hidden relative;
}

.container {
  @apply pl-6 pr-6 w-full mr-auto ml-auto;
  max-width: 1286px;
}

.container- {
  @apply pl-6 pr-6 w-full mr-auto ml-auto;
  max-width: 774px;
}

.container-- {
  @apply pl-6 pr-6 w-full mr-auto ml-auto;
  max-width: 774px;
}

.container--- {
  @apply pl-6 pr-6 w-full mr-auto ml-auto;
  max-width: 736px;
}

.container---- {
  @apply pl-6 pr-6 w-full mr-auto ml-auto;
  max-width: 588px;
}