

/**
 * @license
 * MyFonts Webfont Build ID 4176966, 2021-09-06T16:45:29-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Swiss721BT-Regular by Bitstream
 * URL: https://www.myfonts.com/fonts/bitstream/swiss-721/std-roman/
 *
 * Webfont: Swiss721BT-Medium by Bitstream
 * URL: https://www.myfonts.com/fonts/bitstream/swiss-721/std-medium/
 *
 * Webfont: Swiss721BT-Bold by Bitstream
 * URL: https://www.myfonts.com/fonts/bitstream/swiss-721/std-bold/
 *
 * Webfont: Swiss721BT-Extended by Bitstream
 * URL: https://www.myfonts.com/fonts/bitstream/swiss-721/std-extended/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 1990 -2018 MyFonts Inc. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
//@import url("//hello.myfonts.net/count/3fbc46");

// @font-face {
//   font-family: "Swiss721BT-Bold";
//   src: url('../../fonts/Swiss721BTBold/font.woff2') format('woff2'), url('../../fonts/Swiss721BTBold/font.woff') format('woff');
// }
// @font-face {
//   font-family: "Swiss721BT-Regular";
//   src: url('../../fonts/Swiss721BTRegular/font.woff2') format('woff2'), url('../../fonts/Swiss721BTRegular/font.woff') format('woff');
// }
// @font-face {
//   font-family: "Swiss721BT-Medium";
//   src: url('../../fonts/Swiss721BTMedium/font.woff2') format('woff2'), url('../../fonts/Swiss721BTMedium/font.woff') format('woff');
// }
// @font-face {
//   font-family: "Swiss721BT-Extended";
//   src: url('../../fonts/Swiss721BTExtended/font.woff2') format('woff2'), url('../../fonts/Swiss721BTExtended/font.woff') format('woff');
// }


@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');
    @font-face{
        font-family: 'Outfit Light';
        font-weight: 300;
        src: url('../../fonts/Outfit-Light/Outfit-Light.woff2') format('woff2'),url('../../fonts/Outfit-Light/Outfit-Light.woff') format('woff');
    }
    @font-face{
        font-family: 'Outfit Medium';
        font-weight: 500;
        src: url('../../fonts/Outfit-Medium/Outfit-Medium.woff2') format('woff2'),url('../../fonts/Outfit-Medium/Outfit-Medium.woff') format('woff');
    }
    @font-face{
        font-family: 'Lufga SemiBold';
        font-weight: 600;
        src: url('../../fonts/LufgaSemiBold/LufgaSemiBold.woff2') format('woff2'),url('../../fonts/LufgaSemiBold/LufgaSemiBold.woff') format('woff');
    }
    @font-face{
        font-family: 'Lufga Bold';
        font-weight: 700;
        src: url('../../fonts/LufgaBold/LufgaBold.woff2') format('woff2'),url('../../fonts/LufgaBold/LufgaBold.woff') format('woff');
    }